import { extent } from 'd3-array';
import { scaleLinear } from 'd3-scale';
import { line } from 'd3-shape';

export function SparkLine(props: { data: { x: number; y: number }[] }) {
    const xExtent = extent(props.data, (d) => d.x) as [number, number];
    const xScale = scaleLinear().domain(xExtent).range([0, 60]);

    const yExtent = extent(props.data, (d) => d.y) as [number, number];
    const yScale = scaleLinear().domain(yExtent).range([0, 20]);

    const l = line<{ x: number; y: number }>()
        .x((d) => xScale(d.x))
        .y((d) => 20 - yScale(d.y))
        .defined((d) => d.y !== null);

    return (
        <svg width={60} height={20}>
            <path
                d={l(props.data) ?? ''}
                fill="none"
                stroke="black"
                strokeOpacity="0.5"
                strokeWidth="1"
            />
        </svg>
    );
}
