import { Suspense, lazy, useEffect, useState } from 'react';
import { useDataQuery } from '../../hooks/use-data.hook';
import {
    ApiConfig,
    DataOptions,
    DataPackage,
    Indicator,
    TimeRangeMap,
} from '../../types';
import { ColorLegend } from '../ColorLegend';
import { TimeLine } from '../TimeLine';
import { ViewPicker } from '../ViewPicker';

import { getRouteApi } from '@tanstack/react-router';
import { useMediaQuery } from 'usehooks-ts';
import { useTitle } from '../../hooks/use-title.hook';
import { Spinner } from '../Spinner';
import { Menu } from './Menu';
import { Options } from './Options';
import { getMinimumTimerange } from '../../get-minimumtimerange';
import { featureIsIncludedInCurrentFilter } from '../../helpers/filter';
import { set } from 'zod';

const routeApi = getRouteApi('/');

const Viz = lazy(() => import('../Viz/Viz'));

type Props = {
    config: ApiConfig;
    indicators: Indicator[];
};

let userNavigated = false;

export function Tool(props: Props) {
    useTitle('Huddingeanalysen');

    const timeRangeMap = props.indicators.reduce((obj, curr) => {
        if (!curr.timeRanges) {
            console.error(
                `Timerange not found in indicator ${JSON.stringify(curr)}`
            );
            return obj;
        }

        return {
            ...obj,
            [curr.id]: curr.timeRanges,
        };
    }, {} as TimeRangeMap);

    const indicatorsMap = props.indicators.reduce((obj, curr) => {
        obj[curr.id] = curr;
        return obj;
    }, {} as Record<string, Indicator>);

    const isNarrow = useMediaQuery('(max-width: 640px)');

    const navigate = routeApi.useNavigate();
    const search = routeApi.useSearch();

    const [showScatterPaths, setShowScatterPaths] = useState<boolean>(false);
    const [showLabelsSelected, setShowLabelsSelected] = useState<boolean>(true);

    const [notSelectedOpacity, setNotSelectedOpacity] = useState<{
        features: number;
        lines: number;
    }>({ features: 0.4, lines: 0.2 });
    const [labelSize, setLabelSize] = useState<number>(16);
    const [circleSize, setCircleSize] = useState<{ min: number; max: number }>({
        min: 4,
        max: 15,
    });

    const xVar =
        indicatorsMap[search.indicators?.x ?? ''] ??
        indicatorsMap[props.config.defaultDataPackage.x];
    const yVar =
        indicatorsMap[search.indicators?.y ?? ''] ??
        indicatorsMap[props.config.defaultDataPackage.y];
    const rVar =
        indicatorsMap[search.indicators?.r ?? ''] ??
        indicatorsMap[props.config.defaultDataPackage.r];
    let cVar: Indicator | null = null;

    if (search.indicators?.c !== null) {
        cVar =
            indicatorsMap[search.indicators?.c ?? ''] ??
            indicatorsMap[props.config.defaultDataPackage.c];
    }

    const targetOptions: DataOptions = {
        indicators: {
            xVar,
            yVar,
            rVar,
            cVar,
        },
        geoLevel: search.geoLevel,
    };

    const dataQuery = useDataQuery(targetOptions, indicatorsMap);

    useEffect(() => {
        if (!dataQuery.data) {
            return;
        }

        const timeRange = getMinimumTimerange({
            dataOptions: dataQuery.data.options,
            timeRangeMap,
        });

        navigate({
            replace: true,
            search: {
                ...search,
                year: userNavigated ? timeRange[0] : search.year,
                animationBuster: new Date().getTime(),
                indicators: {
                    x: dataQuery.data.options.indicators.xVar.id,
                    y: dataQuery.data.options.indicators.yVar.id,
                    r: dataQuery.data.options.indicators.rVar.id,
                    c: dataQuery.data.options.indicators.cVar?.id ?? null,
                },
            },
        });

        userNavigated = false;
    }, [dataQuery.data]);

    const kommunQuery = useDataQuery(
        {
            ...targetOptions,
            geoLevel: 'kommun',
        },
        indicatorsMap
    );

    if (dataQuery.error) {
        return (
            <div className="fixed inset-0 grid place-items-center">
                <div className="flex flex-col gap-1">
                    Oops!
                    <a href="/" className="link-secondary">
                        reset
                    </a>
                </div>
            </div>
        );
    }

    if (!dataQuery.data) {
        return <Spinner loading={true} />;
    }

    const timeRange = getMinimumTimerange({
        dataOptions: dataQuery.data.options,
        timeRangeMap,
    });

    const dataPackages = props.config.dataPackages;

    const selectedDataPackage =
        dataPackages.find((datapackage) => {
            if (!xVar || !yVar || !rVar) {
                return false;
            }
            return (
                xVar.id === datapackage?.x &&
                yVar.id === datapackage?.y &&
                rVar.id === datapackage?.r &&
                (!cVar || cVar.id === datapackage?.c)
            );
        }) ?? null;

    const selectedFeatures = search.selectedFeatures ?? [];
    function setSelectedFeatures(features: string[]) {
        navigate({
            replace: true,
            search: {
                ...search,
                selectedFeatures: features,
            },
        });
    }

    function handleSetSelectedDatapackage(newPackage: DataPackage) {
        const newPackageMissingIndicators =
            !newPackage.x || !newPackage.y || !newPackage.r;
        const indicatorsMapMissingIndicators = [
            newPackage.x,
            newPackage.y,
            newPackage.r,
        ].some((d) => !indicatorsMap[d]);

        if (newPackageMissingIndicators || indicatorsMapMissingIndicators) {
            console.error(
                `Missing indicators in newPackage:\n${JSON.stringify(
                    newPackage,
                    null,
                    4
                )}\n\nor indicatorsMap:\n${JSON.stringify(
                    indicatorsMap,
                    null,
                    4
                )}`
            );
            return;
        }

        const cVar = newPackage.c ? indicatorsMap[newPackage.c] : null;

        userNavigated = true;

        navigate({
            replace: true,
            search: {
                ...search,
                indicators: {
                    x: newPackage.x,
                    y: newPackage.y,
                    r: newPackage.r,
                    c: newPackage.c,
                },
                colorType: cVar?.colorType ?? undefined,
                scaleType: cVar?.scaleType ?? undefined,
            },
        });
    }

    let { points, polygons, options } = dataQuery.data;

    function setVar(indicator: Indicator, key: string) {
        userNavigated = true;

        const newVar = indicatorsMap[indicator.id];

        let colorType = search.colorType;
        let scaleType = search.scaleType;

        if (key === 'c') {
            colorType = newVar.colorType ?? colorType;
            scaleType = newVar.scaleType ?? scaleType;
        }

        navigate({
            replace: true,
            search: {
                ...search,
                indicators: {
                    ...search.indicators,
                    [key]: indicator.id,
                },
                colorType,
                scaleType,
            },
        });
    }

    return (
        <div className="h-full p-3 bg-base-300 flex flex-col gap-3 overflow-x-hidden">
            <div className="flex flex-row gap-3 flex-1 h-full min-h-0 relative">
                <Menu
                    indicators={props.indicators ?? []}
                    config={props.config}
                    dataPackages={dataPackages}
                    selectedDataPackage={selectedDataPackage}
                    timeRangeMap={timeRangeMap}
                    timeRange={timeRange}
                    dataOptions={dataQuery.data.options}
                    onSetXVar={(indicator) => setVar(indicator, 'x')}
                    onSetYVar={(indicator) => setVar(indicator, 'y')}
                    onSetRVar={(indicator) => setVar(indicator, 'r')}
                    onSetCVar={(indicator) => {
                        if (indicator) {
                            setVar(indicator, 'c');
                        } else {
                            userNavigated = true;
                            navigate({
                                replace: true,
                                search: {
                                    ...search,
                                    indicators: {
                                        ...search.indicators,
                                        c: null,
                                    },
                                },
                            });
                        }
                    }}
                    data={dataQuery.data}
                    indicatorsMap={indicatorsMap}
                    onSetSelectedDatapackage={handleSetSelectedDatapackage}
                    onSetSelectedFeatures={setSelectedFeatures}
                    points={points}
                />

                <div className="flex flex-col h-full w-full gap-3">
                    {/* Main visualization */}
                    <div className="bg-white h-full flex-1 rounded-lg relative">
                        <Suspense fallback={<Spinner loading />}>
                            <Viz
                                dataOptions={options}
                                dataFeatures={points}
                                polygons={polygons}
                                kommunData={kommunQuery.data}
                                year={search.year ?? timeRange[0]}
                                selectedFeatures={selectedFeatures}
                                onClearSelection={() => setSelectedFeatures([])}
                                onSelectAll={() => {
                                    setSelectedFeatures(
                                        points
                                            .filter((d) =>
                                                featureIsIncludedInCurrentFilter(
                                                    d,
                                                    search.featureFilter
                                                )
                                            )
                                            .map((d) =>
                                                String(d.properties?.id)
                                            )
                                    );
                                }}
                                onFeatureClick={(id) => {
                                    if (!selectedFeatures.includes(id)) {
                                        setSelectedFeatures([
                                            ...selectedFeatures,
                                            id,
                                        ]);
                                    } else {
                                        setSelectedFeatures(
                                            selectedFeatures.filter(
                                                (_id) => _id !== id
                                            )
                                        );
                                    }
                                }}
                                showScatterPaths={showScatterPaths}
                                notSelectedOpacity={notSelectedOpacity}
                                circleSize={circleSize}
                                showLabelsSelected={showLabelsSelected}
                                labelSize={labelSize}
                                viewStateInUrl={true}
                                timeRangeMap={timeRangeMap}
                                animationBuster={search.animationBuster}
                                featureFilter={search.featureFilter}
                                zoomToFiltered={search.zoomToFiltered}
                                mapType={search.mapType}
                                view={search.view}
                                onViewStateChange={(viewState) => {
                                    navigate({
                                        replace: true,
                                        search: {
                                            ...search,
                                            ...viewState,
                                        },
                                    });
                                }}
                                colorScale={search.colorScale}
                                colorType={search.colorType}
                                scaleType={search.scaleType}
                            />
                        </Suspense>
                    </div>
                    <div className="flex gap-3">
                        <div className="flex flex-col gap-3 flex-1">
                            <div className="flex justify-between">
                                <div className="flex-1 flex justify-center items-start gap-6">
                                    <ViewPicker />
                                </div>
                                <Options
                                    circleSize={circleSize}
                                    setCircleSize={setCircleSize}
                                    labelSize={labelSize}
                                    setLabelSize={setLabelSize}
                                    notSelectedOpacity={notSelectedOpacity}
                                    setNotSelectedOpacity={
                                        setNotSelectedOpacity
                                    }
                                    showLabelsSelected={showLabelsSelected}
                                    setShowLabelsSelected={
                                        setShowLabelsSelected
                                    }
                                    showScatterPaths={showScatterPaths}
                                    setShowScatterPaths={setShowScatterPaths}
                                    selectedFeatures={selectedFeatures}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex gap-3">
                <div className="bg-base-100 rounded-lg flex-1">
                    <TimeLine key={timeRange.join('-')} timeRange={timeRange} />
                </div>
                {cVar && (
                    <div className="bg-base-100 rounded-lg p-3">
                        <ColorLegend
                            showHeader={false}
                            cVar={options.indicators.cVar}
                            data={points}
                            featureFilter={search.featureFilter}
                            zoomToFiltered={search.zoomToFiltered}
                            colorScale={search.colorScale}
                            colorType={search.colorType}
                            scaleType={search.scaleType}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
