type Props = {
    fill?: string;
    size?: number;
    className?: string;
};

export function PackageIcon({
    fill = 'black',
    size = 24,
    className = '',
}: Props) {
    return (
        <svg
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            height={`${size}px`}
            width={`${size}px`}
            fill={fill}
            viewBox="0 96 960 960"
        >
            <path d="m186.666 742 260.001 150.334V595L186.666 444.666V742Zm326.667 150.334L773.334 742V444L513.333 595v297.334ZM222.999 389 480 538l256.334-149L480 240.666 222.999 389ZM120 781V409.666q0-17.999 8.833-33.333Q137.667 361 153.333 352l293.334-169q15.666-9 33.333-9 17.667 0 33.333 9l293.334 169q15.666 9 24.5 24.333Q840 391.667 840 409.666v332.668q0 17.999-8.833 33.333Q822.333 791 806.667 800L513.333 969q-15.666 9-33.333 9-17.667 0-33.333-9L120 781Zm360-205Z" />
        </svg>
    );
}
