type Props = {
    size?: number;
    fill?: string;
};

export function XAxisIcon(props: Props) {
    const { size = 40, fill = 'black' } = props;

    return (
        <svg
            width={`${size}px`}
            height={`${size}px`}
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_3_5)">
                <rect width="100" height="100" fill="transparent" />
                <rect x="7" y="6" width="3" height="87" rx="1.5" fill={fill} />
                <rect x="7" y="77" width="87" height="16" rx="3" fill={fill} />
                <path
                    d="M77.1818 50.7273L83.1818 60.4091H83.3636L89.3636 50.7273H92.6818L85.3636 62.3636L92.6818 74H89.3636L83.3636 64.5H83.1818L77.1818 74H73.8636L81.3636 62.3636L73.8636 50.7273H77.1818Z"
                    fill={fill}
                />
            </g>
            <defs>
                <clipPath id="clip0_3_5">
                    <rect width="100" height="100" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
