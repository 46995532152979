type Props = {
    size?: number;
    fill?: string;
};

export function YAxisIcon(props: Props) {
    const { size = 40, fill = 'black' } = props;

    return (
        <svg
            width={`${size}px`}
            height={`${size}px`}
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_2_2)">
                <rect width="100" height="100" fill="transparent" />
                <path
                    d="M7 9C7 7.34315 8.34315 6 10 6H20C21.6569 6 23 7.34315 23 9V90C23 91.6569 21.6569 93 20 93H10C8.34315 93 7 91.6569 7 90V9Z"
                    fill={fill}
                />
                <path
                    d="M7 91.5C7 90.6716 7.67157 90 8.5 90H92.5C93.3284 90 94 90.6716 94 91.5V91.5C94 92.3284 93.3284 93 92.5 93H8.5C7.67157 93 7 92.3284 7 91.5V91.5Z"
                    fill={fill}
                />
                <path
                    d="M28.8182 6.72727H32.0455L38.5 17.5909H38.7727L45.2273 6.72727H48.4545L40.0455 20.4091V30H37.2273V20.4091L28.8182 6.72727Z"
                    fill={fill}
                />
            </g>
            <defs>
                <clipPath id="clip0_2_2">
                    <rect width="100" height="100" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
