import { createFileRoute } from '@tanstack/react-router';
import Introduction from '../components/ScrollyPage/Introduction';

export const Route = createFileRoute('/om/')({
    component: Index,
});

function Index() {
    return <Introduction />;
}
