import { createFileRoute } from '@tanstack/react-router';
import { lazy, Suspense } from 'react';
import { ScrollyArticle } from '../components/ScrollyPage/ScrollyArticle';
import { Spinner } from '../components/Spinner';

const Guide = lazy(() => import('../components/ScrollyPage/Guide'));

export const Route = createFileRoute('/om/guide')({
    component: Index,
});

function Index() {
    return (
        <ScrollyArticle>
            <Suspense fallback={<Spinner />}>
                <Guide />
            </Suspense>
        </ScrollyArticle>
    );
}
