import { Feature } from 'geojson';

export function featureIsIncludedInCurrentFilter(
    d: Feature,
    featureFilter: string[]
) {
    if (featureFilter.length === 0) {
        return true;
    }

    return featureFilter.includes(d.properties?.kommunnamn);
}
