import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18next
    .use(initReactI18next)
    .use(I18nextBrowserLanguageDetector)
    .use(Backend)
    .init({
        supportedLngs: ['sv', 'en'],
        fallbackLng: 'sv',
        interpolation: {
            escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        },
    });
