import * as Sentry from '@sentry/browser';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { FlagsProvider } from 'flagged';
import React from 'react';
import { createRoot } from 'react-dom/client';

import { RouterProvider, createRouter } from '@tanstack/react-router';
import { routeTree } from './routeTree.gen';

import { FEATURES } from './constants';
import './i18n';
import './index.css';
import { AuthProvider } from './components/AuthProvider';

if (!import.meta.env.DEV) {
    Sentry.init({
        dsn: 'https://90f21ea1451749839a3c2c9e0e567374@glitchtip-web-production-3c67.up.railway.app/1',
    });
}

const router = createRouter({ routeTree });
router.load(); // Put this here to force index route to run loader on first render. Feels wrong though.
declare module '@tanstack/react-router' {
    interface Register {
        router: typeof router;
    }
}

const queryClient = new QueryClient();

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    <React.StrictMode>
        <FlagsProvider features={FEATURES}>
            <QueryClientProvider client={queryClient}>
                <AuthProvider>
                    <RouterProvider router={router} />
                </AuthProvider>
            </QueryClientProvider>
        </FlagsProvider>
    </React.StrictMode>
);
