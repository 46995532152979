import { Dispatch, SetStateAction, useState } from 'react';
import { ColorSettings } from './ColorSettings';
import { FullscreenIcon } from '../Icons';
import { SettingsIcon } from '../Icons/SettingsIcon';
import { useTranslation } from 'react-i18next';
import { useFullScreen } from '../../hooks/use-fullscreen';
import { Link } from '@tanstack/react-router';
import { Feature } from 'flagged';
import { logout, useIsLoggedIn } from '../AuthProvider';

export function Options(props: {
    showScatterPaths: boolean;
    setShowScatterPaths: (value: boolean) => void;
    showLabelsSelected: boolean;
    setShowLabelsSelected: (value: boolean) => void;
    labelSize: number;
    setLabelSize: (value: number) => void;
    circleSize: { min: number; max: number };
    setCircleSize: Dispatch<SetStateAction<{ min: number; max: number }>>;
    notSelectedOpacity: { features: number; lines: number };
    setNotSelectedOpacity: Dispatch<
        SetStateAction<{ features: number; lines: number }>
    >;
    selectedFeatures: string[];
}) {
    const [showSettingsPanel, setShowSettingsPanel] = useState(false);
    const fullscreenHandle = useFullScreen();
    const { isLoggedIn, recheckAuth } = useIsLoggedIn();

    const { t } = useTranslation();

    return (
        <>
            <div className="flex gap-4 items-center">
                {props.selectedFeatures.length > 0 && (
                    <label className={`flex flex-row gap-2 items-center`}>
                        <span
                            className={`
                                                    ${
                                                        props.selectedFeatures
                                                            .length === 0
                                                            ? 'text-gray-400'
                                                            : undefined
                                                    }`}
                        >
                            {t('opacity-bubbles')}
                        </span>
                        <input
                            disabled={props.selectedFeatures.length === 0}
                            type="range"
                            min="0"
                            max="0.6"
                            step="0.01"
                            value={props.notSelectedOpacity.features}
                            onChange={(e) => {
                                props.setNotSelectedOpacity((value) => ({
                                    ...value,
                                    features: +e.target.value,
                                }));
                            }}
                        />
                    </label>
                )}
                <label className="flex flex-row gap-2 cursor-pointer items-center">
                    <input
                        type="checkbox"
                        checked={props.showScatterPaths}
                        onChange={() =>
                            props.setShowScatterPaths(!props.showScatterPaths)
                        }
                        className="checkbox checkbox-primary checkbox-xs"
                    />
                    {t('Linjer')}
                </label>

                <div className="flex gap-2">
                    <label className="btn btn-sm btn-circle btn-primary swap swap-rotate flex">
                        <input
                            checked={showSettingsPanel}
                            onChange={() =>
                                setShowSettingsPanel((value) => !value)
                            }
                            type="checkbox"
                        />
                        <div className="absolute swap-off">
                            <div className="indicator">
                                <Feature name="USER_LOGIN">
                                    {isLoggedIn && (
                                        <span className="indicator-item badge badge-warning"></span>
                                    )}
                                </Feature>
                                <SettingsIcon className="fill-white" />
                            </div>
                        </div>
                        <svg
                            className="absolute swap-on fill-white"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 512 512"
                        >
                            <polygon points="400 145.49 366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49" />
                        </svg>
                    </label>
                    <button
                        type="button"
                        className="btn btn-sm btn-circle btn-primary"
                        onClick={() => {
                            if (fullscreenHandle.active) {
                                fullscreenHandle.exit();
                            } else {
                                fullscreenHandle.enter();
                            }
                        }}
                    >
                        <FullscreenIcon />
                    </button>
                </div>
            </div>
            {showSettingsPanel && (
                <div className="bg-base-100 rounded-lg p-3 shadow-xl flex flex-col gap-3 text-sm absolute bottom-16 right-2">
                    <Feature name="USER_LOGIN">
                        {isLoggedIn && (
                            <div className="self-end text-warning">
                                <span>Du är inloggad</span>
                            </div>
                        )}
                    </Feature>

                    <div className="flex">
                        <h2 className="flex-1 text-lg">{t('settings')}</h2>
                        <Feature name="USER_LOGIN">
                            <div>
                                {isLoggedIn ? (
                                    <button
                                        type="button"
                                        className="btn btn-xs btn-ghost"
                                        onClick={() =>
                                            logout().then(recheckAuth)
                                        }
                                    >
                                        Logga ut
                                    </button>
                                ) : (
                                    <Link to="/login">
                                        <button
                                            type="button"
                                            className="btn btn-xs btn-ghost"
                                        >
                                            Logga in
                                        </button>
                                    </Link>
                                )}
                            </div>
                        </Feature>
                    </div>
                    <div className="flex flex-col gap-6">
                        <div className="flex flex-col gap-1">
                            <label className="flex flex-row gap-2 cursor-pointer items-center">
                                <input
                                    type="checkbox"
                                    checked={props.showLabelsSelected}
                                    onChange={() =>
                                        props.setShowLabelsSelected(
                                            !props.showLabelsSelected
                                        )
                                    }
                                    className="checkbox checkbox-primary checkbox-xs"
                                />
                                {t('etikett-markerade')}
                            </label>
                            <label className={`flex flex-col max-w-xs`}>
                                {t('size-labels')}
                                <label className="flex items-center gap-1">
                                    <input
                                        className=""
                                        type="range"
                                        min="8"
                                        max="35"
                                        step="1"
                                        value={props.labelSize}
                                        onChange={(e) => {
                                            props.setLabelSize(+e.target.value);
                                        }}
                                    />
                                </label>
                            </label>
                        </div>

                        <label className={`flex flex-col max-w-xs`}>
                            {t('not-selected')}
                            <label className={`flex flex-col max-w-xs`}>
                                <span
                                    className={`${
                                        props.selectedFeatures.length === 0
                                            ? 'text-gray-400'
                                            : undefined
                                    }`}
                                >
                                    {t('opacity-bubbles')}
                                </span>
                                <input
                                    disabled={
                                        props.selectedFeatures.length === 0
                                    }
                                    type="range"
                                    min="0"
                                    max="0.6"
                                    step="0.01"
                                    value={props.notSelectedOpacity.features}
                                    onChange={(e) => {
                                        props.setNotSelectedOpacity(
                                            (value) => ({
                                                ...value,
                                                features: +e.target.value,
                                            })
                                        );
                                    }}
                                />
                            </label>
                            <label className={`flex flex-col max-w-xs`}>
                                <span
                                    className={`${
                                        props.selectedFeatures.length === 0 ||
                                        !props.showScatterPaths
                                            ? 'text-gray-400'
                                            : undefined
                                    }`}
                                >
                                    {t('opacity-lines')}
                                </span>
                                <input
                                    disabled={
                                        props.selectedFeatures.length === 0 ||
                                        !props.showScatterPaths
                                    }
                                    type="range"
                                    min="0"
                                    max="0.6"
                                    step="0.01"
                                    value={props.notSelectedOpacity.lines}
                                    onChange={(e) => {
                                        props.setNotSelectedOpacity(
                                            (value) => ({
                                                ...value,
                                                lines: +e.target.value,
                                            })
                                        );
                                    }}
                                />
                            </label>
                        </label>

                        <label className={`flex flex-col max-w-xs`}>
                            {t('bubble-size')}
                            <label className="flex items-center gap-1">
                                Min
                                <input
                                    className=""
                                    type="range"
                                    min="1"
                                    max="50"
                                    step="1"
                                    value={props.circleSize.min}
                                    onChange={(e) => {
                                        props.setCircleSize((currentSize) => ({
                                            ...currentSize,
                                            min: Math.min(
                                                +e.target.value,
                                                currentSize.max
                                            ),
                                        }));
                                    }}
                                />
                            </label>
                            <label className="flex items-center gap-1">
                                Max
                                <input
                                    className=""
                                    type="range"
                                    min="1"
                                    max="50"
                                    step="1"
                                    value={props.circleSize.max}
                                    onChange={(e) => {
                                        props.setCircleSize((currentSize) => ({
                                            ...currentSize,
                                            max: Math.max(
                                                +e.target.value,
                                                currentSize.min
                                            ),
                                        }));
                                    }}
                                />
                            </label>
                        </label>
                        <ColorSettings />
                    </div>
                </div>
            )}
        </>
    );
}
