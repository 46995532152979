import { Outlet, createRootRoute } from '@tanstack/react-router';
import { TanStackRouterDevtools } from '@tanstack/router-devtools';

export const Route = createRootRoute({
    component: () => {
        return (
            <>
                <Outlet />
                {import.meta.env.DEV && <TanStackRouterDevtools />}
            </>
        );
    },
});
