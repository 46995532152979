import { Link } from '@tanstack/react-router';
import { BookIcon } from '../Icons/BookIcon';
import { ChartIcon } from '../Icons/ChartIcon';
import { LanguageSwitch } from '../LanguageSwitch';
import { useTranslation } from 'react-i18next';
import { searchSchema } from '../../routes';

type NavLink = {
    to: string;
    label: string;
    icon?: JSX.Element;
};

export function Nav() {
    const { t } = useTranslation();

    const navLinks: NavLink[] = [
        { to: '/om', label: t('introduction') },
        {
            to: '/om/socioekonomi',
            label: t('socioeconomy'),
            icon: <BookIcon className="fill-info" />,
        },
        {
            to: '/om/lyckoparadoxen',
            label: t('happinessParadox'),
            icon: <BookIcon className="fill-warning" />,
        },
        {
            to: '/om/bostader',
            label: t('housing'),
            icon: <BookIcon className="fill-secondary" />,
        },
        {
            to: '/om/barn-utbildning',
            label: t('childrenEducation'),
            icon: <BookIcon className="fill-error" />,
        },
        {
            to: '/om/kon-inkomst',
            label: t('genderIncome'),
            icon: <BookIcon className="fill-primary" />,
        },
        { to: '/om/guide', label: t('guide') },
    ];

    return (
        <div className="h-full flex flex-col">
            <h1 className="text-xl">Huddingeanalysen</h1>
            <nav className="mt-4 flex flex-col flex-1">
                <ol className="list-none p-0 flex-1">
                    {navLinks.map((link) => (
                        <li key={link.to}>
                            <Link
                                className="link pl-2 border-l-4 border-primary border-opacity-0 flex items-center gap-2 mt-3"
                                activeOptions={{
                                    exact: true,
                                }}
                                activeProps={{
                                    className: 'link-primary',
                                }}
                                to={link.to}
                            >
                                {link.icon && <div>{link.icon}</div>}
                                {link.label}
                            </Link>
                        </li>
                    ))}
                </ol>
                <div className="mt-6 flex flex-col gap-3">
                    <div className="self-start">
                        <LanguageSwitch />
                    </div>
                    <Link to="/" search={searchSchema.parse({})}>
                        <button className="btn btn-primary no-animation text-white">
                            <div className="flex gap-1 items-center">
                                <ChartIcon fill="white" size={30} />
                                {t('till-verktyget')}
                            </div>
                        </button>
                    </Link>
                </div>
            </nav>
        </div>
    );
}
