type Props = {
    fill?: string;
    size?: number;
    className?: string;
};

export function DownloadIcon({
    fill = 'black',
    size = 20,
    className = '',
}: Props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={`${size}px`}
            width={`${size}px`}
            fill={fill}
            viewBox="0 0 20 20"
        >
            <path d="M5.5 16q-.625 0-1.062-.438Q4 15.125 4 14.5V13h1.5v1.5h9V13H16v1.5q0 .625-.438 1.062Q15.125 16 14.5 16Zm4.5-3L6 9l1.062-1.062 2.188 2.187V3h1.5v7.125l2.188-2.187L14 9Z" />
        </svg>
    );
}
