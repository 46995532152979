type TimeSliderProps = {
    min: number;
    max: number;
    value: number;
    step: number;
    onChange: (value: number) => void;
};

export function TimeSlider({
    min,
    max,
    value,
    step,
    onChange,
}: TimeSliderProps) {
    const nTicks = max - min;
    const activeTick = Math.ceil(value);

    return (
        <div className="flex flex-col w-full">
            <input
                type="range"
                className="rangen"
                min={min}
                step={step}
                max={max}
                value={value}
                onChange={(e) => onChange(+e.target.value)}
            />
            <div className="w-full flex justify-between text-2xs lg:text-xs px-3">
                {Array.from({ length: nTicks + 1 }).map((_, i) => {
                    const label = min + i;
                    const isActive = label === activeTick;
                    return (
                        <div
                            className="relative"
                            key={i}
                            style={{ marginTop: '-8px' }}
                        >
                            <div
                                style={{ transform: 'translateX(-50%)' }}
                                className="flex flex-col items-center"
                            >
                                <svg width="3" height="10">
                                    <line
                                        strokeWidth={isActive ? 2 : 1}
                                        stroke="black"
                                        x1={2}
                                        x2={2}
                                        y1={isActive ? 3 : 0}
                                        y2={10}
                                    />
                                </svg>
                                <span
                                    className={`hidden xl:inline-block ${
                                        isActive && 'font-bold underline'
                                    }`}
                                >
                                    {label}
                                </span>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="xl:hidden w-full flex justify-center pt-1">
                {Math.ceil(value)}
            </div>
        </div>
    );
}
