import { useTranslation } from 'react-i18next';
import { File } from '../components/FileViewer';
import { Indicator } from '../types';

// Transform indicators to structure usable by the file picker menu.
export function useIndicatorFiles(
    indicators: Indicator[],
    filter?: (indicator: Indicator) => boolean
) {
    const { i18n } = useTranslation();

    const f = indicators
        .filter((i) => {
            if (!filter) {
                return i.show;
            } else {
                return i.show && filter(i);
            }
        })
        .sort((a, b) => {
            if (!a.category || !b.category) return 0;
            return (
                a.category[i18n.language] ?? a.category['sv']
            ).localeCompare(b.category[i18n.language] ?? b.category['sv']);
        })
        .reduce((arr, curr) => {
            const category = curr.category ?? { sv: 'Övrigt' };

            let directory = arr
                .filter((f) => f.type === 'directory')
                .find(
                    (f) =>
                        f.name === (category[i18n.language] ?? category['sv'])
                );

            if (!directory) {
                directory = {
                    name: category[i18n.language] ?? category['sv'],
                    type: 'directory',
                    contents: [],
                    id: category[i18n.language] ?? category['sv'],
                };

                arr.push(directory);
            }

            // Do nothing
            if (directory.type !== 'directory') {
                console.error('Expected directory but found', directory.type);
                return arr;
            }

            let subDirectory = directory.contents
                .filter((f) => f.type !== 'divider')
                .find(
                    (f) =>
                        f.name ===
                        (curr.label[i18n.language] ?? curr.label['sv'])
                );

            if (!subDirectory) {
                subDirectory = {
                    name: curr.label[i18n.language] ?? curr.label['sv'],
                    id: curr.id,
                    type: 'directory',
                    contents: [],
                    meta: 'label',
                };

                directory.contents.push(subDirectory);
            }

            // Do nothing
            if (subDirectory.type !== 'directory') {
                console.error(
                    'Expected directory but found',
                    subDirectory.type
                );
                return arr;
            }

            const existingSublabel = subDirectory.contents
                .filter((f) => f.type !== 'divider')
                .find(
                    (f) =>
                        f.name === curr.subLabel[i18n.language] ??
                        curr.subLabel['sv']
                );
            if (!existingSublabel) {
                subDirectory.contents.push({
                    name: curr.subLabel[i18n.language] ?? curr.subLabel['sv'],
                    type: 'file',
                    id: curr.id,
                });
            }

            return arr;
        }, [] as File[]);

    return f;
}
