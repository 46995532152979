import { MouseEvent, useRef, useState } from 'react';
import { useOnClickOutside } from '../../hooks/use-clickoutside.hook';
import { Indicator } from '../../types';
import { File } from '../FileViewer';
import { Portal } from '../Portal';
import { FileViewer } from './FileViewer';
import { useTranslation } from 'react-i18next';

type SelectProps = {
    files: File[];
    label: string;
    value: string;
    onChange: (value: string) => void;
    labelIcon?: JSX.Element;
    indicatorsMap: Record<string, Indicator>;
};

export function FileSelect({
    files,
    label,
    value,
    onChange,
    labelIcon,
    indicatorsMap,
}: SelectProps) {
    const { i18n } = useTranslation();

    const [viewerIsOpen, setViewerIsOpen] = useState<boolean>(false);
    const [viewerPos, setViewerPos] = useState<{ x: number; y: number }>({
        x: 500,
        y: 500,
    });

    let activeIndicator: Indicator;

    if (value === 'kommun') {
        activeIndicator = {
            id: 'kommun',
            label: { sv: 'Kommun' },
            subLabel: { sv: '' },
            show: true,
            unitFormat: 'st',
            urls: {
                deso: '',
                regso: '',
                kommun: '',
                nyko6: '',
            },
            valueKey: '',
            category: { sv: 'Administration' },
            description: { sv: '' },
        };
    } else {
        activeIndicator = indicatorsMap[value];
    }

    const fileViewerRef = useRef<HTMLDivElement | null>(null);

    useOnClickOutside(fileViewerRef, (e) => {
        setViewerIsOpen(false);
    });

    const handleClick = (e: MouseEvent) => {
        setViewerPos({
            x: e.clientX,
            y: e.clientY,
        });
        setViewerIsOpen(!viewerIsOpen);
    };

    return (
        <>
            {labelIcon && (
                <span className="grid place-items-center select-label text-right font-bold capitalize">
                    {labelIcon}
                </span>
            )}
            {!labelIcon && (
                <span className="select-label text-right font-bold capitalize">
                    {label}
                </span>
            )}
            <div className="relative">
                {viewerIsOpen && (
                    <Portal>
                        <div
                            className="absolute bg-base-100 rounded-box shadow-2xl  py-3 pr-3"
                            style={{
                                top: `${viewerPos.y}px`,
                                left: `${viewerPos.x}px`,
                                maxHeight: `${
                                    window.innerHeight - viewerPos.y
                                }px`,
                                overflow: 'scroll',
                            }}
                            ref={fileViewerRef}
                        >
                            <FileViewer
                                activeFile={value}
                                files={files}
                                onClickFile={(id) => {
                                    onChange(id);
                                    setViewerIsOpen(false);
                                }}
                            />
                        </div>
                    </Portal>
                )}
                <button
                    type="button"
                    className={`text-left w-full rounded-md  hover:bg-primary hover:text-white hover:rounded-md hover:shadow-lg px-3 py-1 ${
                        viewerIsOpen ? 'bg-primary text-white' : 'bg-base-100'
                    }`}
                    onClick={handleClick}
                >
                    <div className="text-xs">
                        {activeIndicator.category?.[i18n.language] ??
                            activeIndicator.category?.['sv']}
                    </div>
                    <div className="text-base font-light">
                        {activeIndicator.label[i18n.language] ??
                            activeIndicator.label['sv']}
                    </div>
                    <div className="text-sm font-bold">
                        {activeIndicator.subLabel[i18n.language] ??
                            activeIndicator.subLabel['sv']}
                    </div>
                </button>
            </div>
        </>
    );
}
