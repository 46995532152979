import { max, min } from 'd3-array';

import type { TimeRangeMap, DataOptions } from './types';

type Args = {
    dataOptions: DataOptions;
    timeRangeMap: TimeRangeMap;
};

export function getMinimumTimerange({
    dataOptions,
    timeRangeMap,
}: Args): number[] {
    const extents = Object.values(dataOptions.indicators)
        .map((i) => {
            if (!i) return null;
            return timeRangeMap[i.id][dataOptions.geoLevel];
        })
        .filter((e) => e !== null);

    const minYear = max(extents.map((e) => e.timeRange[0] ?? -Infinity));

    const maxYear = min(extents.map((e) => e.timeRange[1] ?? Infinity));

    if (!minYear || !maxYear) return [];

    return [minYear, maxYear];
}
