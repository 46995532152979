import { TFunction } from 'i18next';
import { GeoLevel, TimeRangeMap } from '../../types';
import { File } from './index';

type DisableReason =
    | 'geoLevelUnavailable'
    | 'timeRangeUnavailable'
    | 'parentDisabled';

type AddSubLabelOptions = {
    file: File;
    geoLevel: GeoLevel;
    timeRangeMap: TimeRangeMap;
    t: TFunction;
    disableReason?: DisableReason;
    timeRange: number[];
};

export function addSubLabel(options: AddSubLabelOptions): File {
    let updatedFile = JSON.parse(JSON.stringify(options.file)) as File;

    if (updatedFile.type === 'directory') {
        let disableReason: DisableReason | undefined = options.disableReason;
        if (updatedFile.meta === 'label') {
            if (
                !options.timeRangeMap[updatedFile.id] ||
                !options.timeRangeMap[updatedFile.id][options.geoLevel]
            ) {
                disableReason = 'geoLevelUnavailable';
            }

            const fileTimeRange =
                options.timeRangeMap[updatedFile.id]?.[options.geoLevel]
                    ?.timeRange;
            if (
                fileTimeRange &&
                !isTimeRangeOverlapping(fileTimeRange, options.timeRange)
            ) {
                disableReason = 'timeRangeUnavailable';
            }

            if (!disableReason || disableReason === 'timeRangeUnavailable') {
                updatedFile.subLabel = (
                    <div
                        className={`text-xs px-3 text-secondary mb-1 flex gap-1 items-center ${
                            disableReason === 'timeRangeUnavailable'
                                ? 'text-secondary text-opacity-50'
                                : ''
                        }`}
                    >
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="16px"
                                viewBox="0 0 24 24"
                                width="16px"
                                className={`${
                                    disableReason === 'timeRangeUnavailable'
                                        ? 'fill-secondary-content'
                                        : 'fill-secondary'
                                }`}
                            >
                                <path d="M0 0h24v24H0z" fill="none" />
                                <path d="M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z" />
                            </svg>
                        </div>{' '}
                        <div>
                            {
                                options.timeRangeMap[updatedFile.id][
                                    options.geoLevel
                                ].timeRange[0]
                            }{' '}
                            -{' '}
                            {
                                options.timeRangeMap[updatedFile.id][
                                    options.geoLevel
                                ].timeRange[1]
                            }
                        </div>
                    </div>
                );
            } else if (disableReason) {
                updatedFile.disabled = true;
                updatedFile.disabledCause = disableReason;
                updatedFile.subLabel = (
                    <div className="text-xs px-3 text-base-content text-opacity-50 mb-1 flex gap-1 items-center">
                        <div>{options.t('dataNotAvailable')}</div>
                    </div>
                );
            }
        }

        updatedFile.contents = updatedFile.contents.map((f) =>
            addSubLabel({ ...options, file: f, disableReason })
        );
    }

    if (options.disableReason !== undefined && updatedFile.type === 'file') {
        updatedFile.disabled = true;
        updatedFile.disabledCause = options.disableReason;
    }

    return updatedFile;
}

function isTimeRangeOverlapping(range1: number[], range2: number[]): boolean {
    return range1[0] <= range2[1] && range2[0] <= range1[1];
}
