import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';
import { Tool } from '../components/Tool';
import { ApiConfig, Indicator } from '../types';
import { useFeature } from 'flagged';
import { useQuery } from '@tanstack/react-query';
import { configLoader } from '../configLoader';
import { useIsLoggedIn } from '../components/AuthProvider';
import { Spinner } from '../components/Spinner';

const geoLevelSchema = z.union([
    z.literal('deso'),
    z.literal('regso'),
    z.literal('kommun'),
    z.literal('nyko6'),
]);

export const searchSchema = z.object({
    geoLevel: geoLevelSchema.catch('regso'),
    view: z
        .union([
            z.literal('bubble'),
            z.literal('bubble_and_map'),
            z.literal('map'),
        ])
        .catch('bubble_and_map'),
    mapType: z
        .union([z.literal('choropleth'), z.literal('bubbles')])
        .catch('bubbles'),
    featureFilter: z.array(z.string()).catch(['Huddinge']),
    selectedFeatures: z.array(z.string()).catch([]),
    zoomToFiltered: z.boolean().catch(true),
    scaleType: z
        .union([z.literal('naturalbreaks'), z.literal('linear')])
        .catch('naturalbreaks'),
    colorType: z
        .union([z.literal('sequential'), z.literal('diverging')])
        .catch('sequential'),
    indicators: z
        .object({
            x: z.string(),
            y: z.string(),
            r: z.string(),
            c: z.string().nullable().optional(),
        })
        .optional(),
    year: z.number().optional(),
    animationBuster: z.number().optional(),
    colorScale: z.string().optional(),
    lon: z.number().optional(),
    lat: z.number().optional(),
    zoom: z.number().optional(),
});

type Search = z.infer<typeof searchSchema>;

export const Route = createFileRoute('/')({
    validateSearch: (search) => searchSchema.parse(search),
    loader: configLoader,
    component: Index,
});

function Index() {
    const { config } = Route.useLoaderData();
    const { isLoggedIn } = useIsLoggedIn();

    const { data: indicators, isLoading } = useQuery<Indicator[]>({
        queryKey: ['indicators', isLoggedIn],
        queryFn: async () => {
            const request = await fetch(
                import.meta.env.VITE_API_URL + '/api/v1/data/indicators',
                {
                    credentials: 'include',
                }
            );
            const json = await request.json();
            return json;
        },
    });

    if (isLoading || indicators === undefined) {
        return <Spinner loading={true} />;
    }

    return (
        <div className="h-full">
            <Tool config={config} indicators={indicators} />
        </div>
    );
}
