import { MutableRefObject, useEffect } from 'react';

// https://usehooks.com/useOnClickOutside/

export function useOnClickOutside(
    ref: MutableRefObject<HTMLDivElement | null>,
    handler: (event: MouseEvent | TouchEvent) => void
) {
    useEffect(() => {
        if (ref === null) return;
        const listener = (event: MouseEvent | TouchEvent) => {
            // Do nothing if clicking ref's element or descendent elements
            if (
                !ref.current ||
                (event.target instanceof Node &&
                    ref.current.contains(event.target))
            ) {
                return;
            }

            handler(event);
        };

        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);

        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ref, handler]);
}
