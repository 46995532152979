import { Indicator } from './types';

export const indicatorsLoader = async () => {
    const request = await fetch(
        import.meta.env.VITE_API_URL + '/api/v1/data/indicators',
        {
            credentials: 'include',
        }
    );
    const json = await request.json();
    return json as Indicator[];
};
