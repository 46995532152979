import { createFileRoute } from '@tanstack/react-router';
import { lazy, Suspense } from 'react';
import { ScrollyArticle } from '../components/ScrollyPage/ScrollyArticle';
import { Spinner } from '../components/Spinner';

const StoryBarnUtbildning = lazy(
    () => import('../components/ScrollyPage/StoryBarnUtbildning')
);

export const Route = createFileRoute('/om/barn-utbildning')({
    component: Index,
});

function Index() {
    return (
        <ScrollyArticle>
            <Suspense fallback={<Spinner />}>
                <StoryBarnUtbildning />
            </Suspense>
        </ScrollyArticle>
    );
}
