type Props = {
    fill?: string;
    size?: number;
    className?: string;
};

export function ChartIcon({
    fill = 'black',
    size = 40,
    className = '',
}: Props) {
    return (
        // <svg
        //     className={className}
        //     xmlns="http://www.w3.org/2000/svg"
        //     height={`${size}px`}
        //     viewBox="0 0 40 40"
        //     width={`${size}px`}
        //     fill={fill}
        //     stroke={fill}
        // >
        //     <path fill={fill} d="M282.667 778h66.666V501.333h-66.666V778Zm164 0h66.666V374h-66.666v404Zm164 0h66.666V626h-66.666v152ZM186.666 936q-27 0-46.833-19.833T120 869.334V282.666q0-27 19.833-46.833T186.666 216h586.668q27 0 46.833 19.833T840 282.666v586.668q0 27-19.833 46.833T773.334 936H186.666Zm0-66.666h586.668V282.666H186.666v586.668Zm0-586.668v586.668-586.668Z" />

        // </svg>

        <svg
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            height={size}
            width={size}
            viewBox="0 96 960 960"
            fill={fill}
        >
            <path d="M282.667 778h66.666V501.333h-66.666V778Zm164 0h66.666V374h-66.666v404Zm164 0h66.666V626h-66.666v152ZM186.666 936q-27 0-46.833-19.833T120 869.334V282.666q0-27 19.833-46.833T186.666 216h586.668q27 0 46.833 19.833T840 282.666v586.668q0 27-19.833 46.833T773.334 936H186.666Zm0-66.666h586.668V282.666H186.666v586.668Zm0-586.668v586.668-586.668Z" />
        </svg>
    );
}
