import { ApiConfig } from './types';

export const configLoader = async () => {
    const response = await fetch(
        import.meta.env.VITE_API_URL + '/config.json',
        {
            credentials: 'include',
        }
    );
    const json = await response.json();
    return {
        config: json as ApiConfig,
    };
};
