import { createFileRoute } from '@tanstack/react-router';
import { TablePage } from '../components/TablePage';
import { z } from 'zod';
import { useQuery } from '@tanstack/react-query';
import { configLoader } from '../configLoader';
import { indicatorsLoader } from '../loaders';

const geoLevelSchema = z.union([
    z.literal('deso'),
    z.literal('regso'),
    z.literal('kommun'),
    z.literal('nyko6'),
]);

const searchSchema = z.object({
    geoLevel: geoLevelSchema.catch('regso'),
    view: z
        .union([
            z.literal('bubble'),
            z.literal('bubble_and_map'),
            z.literal('map'),
        ])
        .catch('bubble_and_map'),
    mapType: z
        .union([z.literal('choropleth'), z.literal('bubbles')])
        .catch('bubbles'),
    featureFilter: z.array(z.string()).catch(['Huddinge']),
    selectedFeatures: z.array(z.string()).catch([]),
    zoomToFiltered: z.boolean().catch(true),
    scaleType: z
        .union([z.literal('naturalbreaks'), z.literal('linear')])
        .catch('naturalbreaks'),
    colorType: z
        .union([z.literal('sequential'), z.literal('diverging')])
        .catch('sequential'),
    indicators: z
        .object({
            x: z.string(),
            y: z.string(),
            r: z.string(),
            c: z.string().nullable().optional(),
        })
        .optional(),
    year: z.number().optional(),
    animationBuster: z.number().optional(),
    colorScale: z.string().optional(),
    lon: z.number().optional(),
    lat: z.number().optional(),
    zoom: z.number().optional(),
});

export const Route = createFileRoute('/table/')({
    validateSearch: (search) => searchSchema.parse(search),
    component: TableIndex,
    loader: configLoader,
});

function TableIndex() {
    const { config } = Route.useLoaderData();

    const { data: indicators, isLoading } = useQuery({
        queryKey: ['indicators'],
        queryFn: indicatorsLoader,
    });

    if (isLoading || indicators === undefined) {
        return null;
    }

    return <TablePage indicators={indicators} config={config} />;
}
