import { Link, getRouteApi } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { DataOptions, GeoLevelData, TimeRangeMap } from '../../types';
import { useFeature } from 'flagged';

type Props = {
    vertical?: boolean;
    dataOptions: DataOptions;
    timeRange: TimeRangeMap;
    geoLevels: GeoLevelData[];
};

const routeApi = getRouteApi('/');

export function GeoLevelPicker({
    vertical = false,
    dataOptions,
    timeRange,
    geoLevels,
}: Props) {
    const { t } = useTranslation();
    const search = routeApi.useSearch();
    const geoLevel = search.geoLevel;

    const ffUserLogin = useFeature('USER_LOGIN');

    if (!geoLevel) return null;

    return (
        <div
            className={`tabs tabs-sm tabs-boxed ${
                vertical && 'tooltip tooltip-right'
            }`}
            data-tip={t('valj-geografisk-indelning')}
        >
            {geoLevels
                .filter((level) => {
                    if (level.loggedIn) {
                        return ffUserLogin;
                    }
                    return true;
                })
                .map((level) => {
                    const disabled = Object.values(
                        dataOptions.indicators
                    ).reduce((acc, curr) => {
                        if (!curr) return acc;
                        return acc || !timeRange[curr.id][level.id];
                    }, false);
                    return (
                        <Link
                            key={level.id}
                            search={{
                                ...search,
                                geoLevel: level.id,
                                selectedFeatures: [],
                            }}
                            className={`tab ${vertical && 'tab-xs'} ${
                                level.loggedIn ? 'text-warning' : ''
                            } ${geoLevel === level.id && 'tab-active'} ${
                                disabled
                                    ? 'opacity-50 cursor-not-allowed line-through tab-disabled'
                                    : ''
                            }`}
                            onClick={(e) => {
                                if (disabled) {
                                    e.preventDefault();
                                }
                            }}
                        >
                            {vertical ? level.name.charAt(0) : level.name}
                        </Link>
                    );
                })}
        </div>
    );
}
