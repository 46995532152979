import { useTranslation } from 'react-i18next';

type Props = {};

export function Hero(props: Props) {
    const { t } = useTranslation();

    return (
        <div
            className="hero"
            style={{
                backgroundImage: `url("/images/image1.jpg")`,
                minHeight: '50vh',
            }}
        >
            <div className="hero-overlay bg-opacity-60"></div>
            <div className="hero-content text-neutral-content">
                <div className="max-w-md">
                    <h1 className="block sm:hidden mb-5 text-5xl font-bold break-words">
                        {t('hero.huddingeanalysen-hyphenated')}
                    </h1>
                    <h1 className="hidden sm:block mb-5 text-5xl font-bold">
                        {t('hero.huddingeanalysen-normal')}
                    </h1>
                    <p className="mb-5">{t('hero.welcome')}</p>
                </div>
            </div>
        </div>
    );
}
