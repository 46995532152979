import { createFileRoute } from '@tanstack/react-router';
import { lazy, Suspense } from 'react';
import { ScrollyArticle } from '../components/ScrollyPage/ScrollyArticle';
import { Spinner } from '../components/Spinner';

const StoryLyckoparadoxen = lazy(
    () => import('../components/ScrollyPage/StoryLyckoparadoxen')
);

export const Route = createFileRoute('/om/lyckoparadoxen')({
    component: Index,
});

function Index() {
    return (
        <ScrollyArticle>
            <Suspense fallback={<Spinner />}>
                <StoryLyckoparadoxen />
            </Suspense>
        </ScrollyArticle>
    );
}
