// Based on: https://github.com/snakesilk/react-fullscreen/blob/master/src/index.tsx

import fscreen from 'fscreen';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export interface FullScreen {
    active: boolean;
    enter: () => Promise<void>;
    exit: () => Promise<void>;
}

export function useFullScreen(): FullScreen {
    const [active, setActive] = useState<boolean>(false);
    const node = useRef<HTMLElement>(document.body);

    useEffect(() => {
        const handleChange = () => {
            setActive(fscreen.fullscreenElement === node.current);
        };
        fscreen.addEventListener('fullscreenchange', handleChange);
        return () =>
            fscreen.removeEventListener('fullscreenchange', handleChange);
    }, []);

    const enter = useCallback(() => {
        if (fscreen.fullscreenElement) {
            return fscreen.exitFullscreen().then(() => {
                return fscreen.requestFullscreen(node.current);
            });
        } else if (node.current) {
            return fscreen.requestFullscreen(node.current);
        }
    }, []);

    const exit = useCallback(() => {
        if (fscreen.fullscreenElement === node.current) {
            return fscreen.exitFullscreen();
        }
        return Promise.resolve();
    }, []);

    return useMemo(
        () => ({
            active,
            enter,
            exit,
        }),
        [active, enter, exit]
    );
}
