import { Feature, GeoJsonProperties, Geometry } from 'geojson';
import { GeoLevel, Indicator } from '../types';

export function featureValuesToCSV(
    features: Feature<Geometry, GeoJsonProperties>[],
    indicator: Indicator,
    geoLevel: GeoLevel
) {
    if (features.length === 0) {
        return '';
    }

    const referenceValues = features[0].properties?.values[indicator.valueKey];
    if (!referenceValues) {
        return '';
    }

    const years = Object.keys(referenceValues);

    const headerRow = [geoLevel + 'kod', 'kommun', 'namn', ...years].join(', ');
    let csvLines = [headerRow];

    features.forEach((feature) => {
        const line: any[] = [
            `"${feature.properties?.id}"`,
            `"${feature.properties?.kommunnamn}"`,
            `"${feature.properties?.[geoLevel]}"`,
        ];
        years.forEach((year) => {
            line.push(
                feature.properties?.values[indicator.valueKey][year]
                    ? parseFloat(
                          feature.properties?.values[indicator.valueKey][
                              year
                          ].toFixed(1)
                      )
                    : null
            );
        });
        csvLines.push(line.join(', '));
    });

    return csvLines.join('\n');
}

export function featureValuesToCSVAllTidyJoined(
    features: Feature<Geometry, GeoJsonProperties>[],
    geoLevel: GeoLevel
) {
    if (features.length === 0) {
        return '';
    }

    const indicators = Object.keys(features[0].properties?.values);
    const referenceIndicator = indicators[0];
    const referenceValues = features[0].properties?.values[referenceIndicator];
    if (!referenceValues) {
        console.warn('No reference values');
        return '';
    }
    const years = Object.keys(referenceValues);

    const headerRow = [
        geoLevel + 'kod',
        'kommun',
        'namn',
        'year',
        ...indicators,
    ].join(', ');
    let csvLines = [headerRow];

    features.forEach((feature) => {
        years.forEach((year) => {
            const line: any[] = [
                `"${feature.properties?.id}"`,
                `"${feature.properties?.kommunnamn}"`,
                `"${feature.properties?.[geoLevel]}"`,
                `"${year}"`,
            ];

            indicators.forEach((indicator) => {
                line.push(
                    feature.properties?.values[indicator][year]
                        ? parseFloat(
                              feature.properties?.values[indicator][
                                  year
                              ].toFixed(2)
                          )
                        : null
                );
            });
            csvLines.push(line.join(','));
        });
    });

    return csvLines.join('\n');
}
