import { getMinimumTimerange } from '../get-minimumtimerange';
import { DataOptions, TimeRangeMap } from '../types';

type Args = {
    dataOptions: DataOptions;
    timeRangeMap: TimeRangeMap;
};

export function useTimeRangeYears({ dataOptions, timeRangeMap }: Args) {
    const timeRange = getMinimumTimerange({ dataOptions, timeRangeMap });
    const years: number[] = Array(timeRange[1] - timeRange[0] + 1)
        .fill(timeRange[0])
        .map((x, i) => x + i);
    return years;
}
