import { mean } from 'd3-array';
import { Feature } from 'geojson';

export function getFlatValues<T>(features: Feature[], key: string): T[] {
    const flat = features
        .map((d) => {
            const values = Object.values<T>(d.properties?.values[key]);
            return values.filter((v) => !isNaN(v as any));
        })
        .flat();
    return flat;
}

export function getFlatMeanValues(features: Feature[], key: string): number[] {
    const flat = features
        .map((d): number | undefined => {
            const values = Object.values<number>(d.properties?.values[key]);
            const valuesMean = mean(values);
            if (typeof valuesMean === 'number') {
                return valuesMean;
            }
        })
        .flat()
        .filter((d) => typeof d === 'number') as number[];
    return flat;
}
