import centerOfMass from '@turf/center-of-mass';
import { Feature, GeoJsonProperties, Point, Polygon } from 'geojson';

export function polygonsToPoints(
    polygons: Feature<Polygon, GeoJsonProperties>[]
): Feature<Point, GeoJsonProperties>[] {
    return polygons.map((f) => {
        const point = centerOfMass(f);
        return {
            ...point,
            properties: {
                ...f.properties,
            },
        } as Feature<Point, GeoJsonProperties>;
    });
}
