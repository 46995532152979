import { useEffect, useRef, useState } from 'react';

export type PlayerState = 'paused' | 'playing' | 'stopped';

type PlayerOptions = {
    min: number;
    max: number;
    duration: number;
    startValue?: number;
    onNewValue?: (args: { value: number; manual?: boolean }) => void;
};

export function usePlayer({
    min,
    max,
    duration,
    startValue,
    onNewValue,
}: PlayerOptions) {
    const [value, setValue] = useState(
        startValue ? Math.max(startValue, min) : min
    );
    const [state, setState] = useState<PlayerState>('paused');
    const requestRef = useRef<number | null>(null);
    const lastTime = useRef<number | null>(null);
    const progress = useRef<number>(0);

    function animate(currentTime: number) {
        if (state !== 'playing') return;

        if (!lastTime.current) {
            lastTime.current = currentTime;
        }

        const delta = currentTime - lastTime.current;
        lastTime.current = currentTime;

        progress.current += delta / duration;

        const newValue =
            min + (max - min) * Math.max(0, Math.min(progress.current, 1));
        setValue(newValue);
        onNewValue?.({ value: newValue });

        if (progress.current < 1) {
            requestRef.current = requestAnimationFrame(animate);
        } else {
            setState('stopped');
        }
    }

    function play() {
        if (state !== 'playing') {
            setState('playing');
        }
    }

    function pause() {
        if (state === 'playing') {
            setState('paused');
            lastTime.current = null;
        }
    }

    function reset() {
        setState('paused');
        setValue(min);
        onNewValue?.({ value: min, manual: true });
        lastTime.current = null;
        progress.current = 0;
    }

    function _setValue(_value: number) {
        if (state === 'playing') {
            setState('paused');
        }
        const p = (min - _value) / (min - max);
        progress.current = Math.max(0, Math.min(p, 1));
        setValue(_value);
        onNewValue?.({ value: _value, manual: true });
    }

    useEffect(() => {
        if (state === 'playing') {
            requestRef.current = requestAnimationFrame(animate);
        } else if (requestRef.current) {
            cancelAnimationFrame(requestRef.current);
            requestRef.current = null;
        }

        return () => {
            if (requestRef.current) {
                cancelAnimationFrame(requestRef.current);
            }
        };
    }, [state]);

    return { value, play, pause, reset, state, setValue: _setValue };
}
