import { scaleLinear, scaleQuantize } from 'd3-scale';
import type { Feature, Geometry } from 'geojson';
import {
    ColorType,
    ScaleType,
    useColorScale,
} from '../../hooks/use-colorscale';
import type { Indicator } from '../../types';
import { format } from '../../helpers/format';
import { useTranslation } from 'react-i18next';
import { getRouteApi } from '@tanstack/react-router';

type Props = {
    data: Feature<Geometry>[];
    cVar: Indicator | null;
    showHeader?: boolean;
    width?: number;
    featureFilter: string[];
    zoomToFiltered: boolean;
    scaleType?: ScaleType;
    colorType?: ColorType;
    colorScale?: string;
};

const formats: { [key: string]: string } = {
    heldagar: 'd',
    'per person': '.1f',
    '%': '.0%',
    '%d': 'd',
    st: 'd',
    tkr: 'd',
    m2: 'd',
};

export function ColorLegend({
    data,
    cVar,
    showHeader = true,
    width = 320,
    featureFilter,
    zoomToFiltered,
    scaleType: _scaleType,
    colorType,
    colorScale,
}: Props) {
    if (!cVar) return null;

    const { i18n } = useTranslation();

    const { cScale, scaleType } = useColorScale({
        data,
        cVar,
        scaleType: _scaleType ?? cVar.scaleType ?? 'naturalbreaks',
        colorType: colorType ?? cVar.colorType ?? 'sequential',
        featureFilter,
        zoomToFiltered,
        colorScale: cVar ? colorScale : undefined,
    });

    const title = `${cVar.label[i18n.language] ?? cVar.label['sv']}, ${
        cVar.subLabel[i18n.language] ?? cVar.subLabel['sv']
    }${cVar.unitDisplay ? `, ${cVar.unitDisplay}` : ''}`;

    const height = 60;
    const marginTop = 18;
    const marginRight = 10;
    const marginBottom = 16;
    const marginLeft = 0;
    const tickFormat = formats[cVar.unitFormat];

    if (scaleType === 'naturalbreaks') {
        const domain = cScale.domain() as number[];
        let values: number[] = [];
        const thresholds = cScale.quantiles();
        values = [domain[0], ...thresholds, domain[domain.length - 1]];

        const x = scaleLinear()
            .domain([values[0], values[values.length - 1]])
            .range([marginLeft, width - (marginLeft + marginRight)]);

        return (
            <div>
                {showHeader && (
                    <h2 className="text-sm">
                        {cVar.category && cVar.category[i18n.language]}
                    </h2>
                )}
                <svg
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}
                    className="block overflow-visible"
                >
                    <g>
                        {values.slice(0, values.length - 1).map((d, i) => {
                            const rWidth = x(values[i + 1]) - x(values[i]);

                            return (
                                <rect
                                    key={`${d}-${i}`}
                                    x={x(values[i])}
                                    y={marginTop + 5}
                                    height={11}
                                    fill={cScale(values[i])}
                                    width={rWidth}
                                />
                            );
                        })}
                    </g>
                    <g>
                        {values.map((d, i) => {
                            return (
                                <rect
                                    key={`${d}-${i}`}
                                    x={x(values[i])}
                                    y={marginTop + 5}
                                    height={16}
                                    fill="rgba(0, 0, 0, 0.5)"
                                    width={1}
                                />
                            );
                        })}
                    </g>

                    <g>
                        {values.map((d, i) => {
                            return (
                                <g
                                    key={`${d}-${i}`}
                                    transform={`translate(${
                                        x(values[i]) - 2
                                    }, ${marginTop + 29})`}
                                >
                                    <text
                                        fontSize={11}
                                        textAnchor="start"
                                        transform="rotate(45)"
                                        fill="black"
                                        width={1}
                                    >
                                        {format(tickFormat)(d)}
                                    </text>
                                </g>
                            );
                        })}
                    </g>

                    <g>
                        <text fontWeight="bold" y={marginTop} fontSize={11}>
                            {title}
                        </text>
                    </g>
                </svg>
            </div>
        );
    } else if (scaleType === 'linear') {
        const domain = cScale.domain() as number[];

        const values = domain;

        const x = scaleLinear()
            .domain([values[0], values[values.length - 1]])
            .range([marginLeft, width - (marginLeft + marginRight)]);

        return (
            <div>
                {showHeader && (
                    <h2 className="text-sm">
                        {cVar.category && cVar.category[i18n.language]}
                    </h2>
                )}
                <svg
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}
                    className="block overflow-visible"
                >
                    <g>
                        {Array.from(
                            Array(width - marginLeft - marginRight)
                        ).map((_, i) => {
                            return (
                                <rect
                                    key={i}
                                    x={marginLeft + i}
                                    y={marginTop + 5}
                                    height={11}
                                    fill={cScale(x.invert(i))}
                                    width={1}
                                />
                            );
                        })}
                    </g>
                    <g>
                        {values.map((d, i) => {
                            return (
                                <rect
                                    key={d}
                                    x={x(values[i])}
                                    y={marginTop + 5}
                                    height={16}
                                    fill="rgba(0, 0, 0, 0.5)"
                                    width={1}
                                />
                            );
                        })}
                    </g>

                    <g>
                        {values.map((d, i) => {
                            return (
                                <g
                                    key={d}
                                    transform={`translate(${
                                        x(values[i]) - 2
                                    }, ${marginTop + 29})`}
                                >
                                    <text
                                        fontSize={11}
                                        textAnchor="start"
                                        transform="rotate(45)"
                                        fill="black"
                                        width={1}
                                    >
                                        {format(tickFormat)(d)}
                                    </text>
                                </g>
                            );
                        })}
                    </g>

                    <g>
                        <text fontWeight="bold" y={marginTop} fontSize={11}>
                            {title}
                        </text>
                    </g>
                </svg>
            </div>
        );
    } else {
        return null;
    }
}
