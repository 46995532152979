type Props = {
    fill?: string;
    size?: number;
    className?: string;
};

export function HamburgerIcon({
    fill = 'black',
    size = 40,
    className = '',
}: Props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 96 960 960"
            height={`${size}px`}
            width={`${size}px`}
            fill={fill}
            className={className}
        >
            <path d="M120 816v-66.666h720V816H120Zm0-206.667v-66.666h720v66.666H120Zm0-206.667V336h720v66.666H120Z" />
        </svg>
    );
}
