import { MouseEvent, useRef, useState } from 'react';
import { useOnClickOutside } from '../../hooks/use-clickoutside.hook';
import { File } from '../FileViewer';
import { Portal } from '../Portal';
import { FileViewer } from './FileViewer';

type SelectProps = {
    files: File[];
    label: string;
    value: string;
    onChange: (value: string) => void;
    labelIcon?: JSX.Element;
    className?: string;
};

export function FileSelectSmall({
    files,
    label,
    value,
    onChange,
    labelIcon,
    className,
}: SelectProps) {
    const [viewerIsOpen, setViewerIsOpen] = useState<boolean>(false);
    const [viewerPos, setViewerPos] = useState<{ x: number; y: number }>({
        x: 500,
        y: 500,
    });

    const fileViewerRef = useRef<HTMLDivElement | null>(null);

    useOnClickOutside(fileViewerRef, (e) => {
        setViewerIsOpen(false);
    });

    const handleClick = (e: MouseEvent) => {
        setViewerPos({
            x: e.clientX,
            y: e.clientY,
        });
        setViewerIsOpen(!viewerIsOpen);
    };

    return (
        <>
            <div className="relative">
                {viewerIsOpen && (
                    <Portal>
                        <div
                            className="absolute bg-base-100 rounded-box shadow-2xl  py-3 pr-3 no-scrollbar"
                            style={{
                                top: `${viewerPos.y}px`,
                                left: `${viewerPos.x}px`,
                                maxHeight: `${
                                    window.innerHeight - viewerPos.y
                                }px`,
                                overflow: 'scroll',
                            }}
                            ref={fileViewerRef}
                        >
                            <FileViewer
                                activeFile={value}
                                files={files}
                                onClickFile={(id) => {
                                    onChange(id);
                                    setViewerIsOpen(false);
                                }}
                            />
                        </div>
                    </Portal>
                )}
            </div>
            <div className="tooltip  tooltip-right w-full" data-tip={label}>
                <button
                    onClick={handleClick}
                    className={`btn btn-sm btn-primary grid place-items-center w-full ${className}`}
                >
                    {labelIcon}
                </button>
            </div>
        </>
    );
}
