import { format as d3format, formatDefaultLocale } from 'd3-format';

formatDefaultLocale({
    decimal: ',',
    currency: ['', 'kr'],
    grouping: [3],
    thousands: ' ',
});

export const format = d3format;
