import { ReactNode } from 'react';
import { OpenInNewIcon } from '../Icons/OpenInNewIcon';

type Props = {
    href: string;
    children?: ReactNode;
    color?: string;
    fill?: string;
};

export function LinkExternal(props: Props) {
    return (
        <a
            href={props.href}
            target="_blank"
            rel="noopener noreferrer"
            className={`link ${props.color && `text-${props.color}`}`}
        >
            {props.children}
            <span className="inline-block translate-y-0.5 ml-0.5">
                <OpenInNewIcon fill={props.fill} size={16} />
            </span>
        </a>
    );
}
