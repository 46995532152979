type Props = {
    loading?: boolean;
};

export function Spinner(props: Props) {
    const { loading = false } = props;
    return (
        <div
            className={`absolute inset-0 grid place-items-center z-50 pointer-events-none bg-opacity-10 transition-colors duration-75 ${
                loading ? 'bg-primary' : 'bg-none'
            }`}
        >
            <div
                className={` w-16 h-16 rounded-2xl  transition-colors  duration-500 ${
                    loading ? 'bg-primary animate-spin' : 'bg-none'
                }`}
            ></div>
        </div>
    );
}
