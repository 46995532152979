import { useMemo } from 'react';
import { featureIsIncludedInCurrentFilter } from '../../helpers/filter';
import {
    featureValuesToCSV,
    featureValuesToCSVAllTidyJoined,
} from '../../helpers/features-to-csv';
import { Data } from '../../hooks/use-data.hook';
import { GeoLevel, Indicator } from '../../types';
import { DownloadIcon } from '../Icons';
import * as XLSX from 'xlsx';
import { Portal } from '../Portal';
import { useTranslation } from 'react-i18next';
import { Feature, GeoJsonProperties, Geometry } from 'geojson';

function getFileName(
    indicator: Indicator,
    featureFilter: string[],
    language: string,
    extension?: string
): string {
    const fileNameParts = [];

    if (indicator.category?.[language] ?? indicator.category?.['sv']) {
        fileNameParts.push(
            indicator.category?.[language] ?? indicator.category?.['sv']
        );
    }

    fileNameParts.push(
        (indicator.label[language] ?? indicator.label['sv'])
            .replaceAll(' ', '_')
            .replaceAll(',', '')
    );
    fileNameParts.push(
        (indicator.subLabel[language] ?? indicator.subLabel['sv'])
            .replaceAll(' ', '_')
            .replaceAll(',', '')
    );

    if (indicator.unitDisplay) {
        fileNameParts.push(indicator.unitDisplay);
    }

    if (featureFilter.length > 0) {
        fileNameParts.push(featureFilter.join('_'));
    }

    return fileNameParts.join('_') + (extension ? `.${extension}` : '');
}

type Props = {
    indicator: Indicator;
    geoLevel: GeoLevel;
    featureFilter: string[];
    data: Pick<Data, 'points'>;
};

export function DownloadIndicatorButton(props: Props) {
    const { i18n } = useTranslation();

    const csv = useMemo(() => {
        return featureValuesToCSV(
            props.data.points.filter((f) =>
                featureIsIncludedInCurrentFilter(f, props.featureFilter)
            ),
            props.indicator,
            props.geoLevel
        );
        // return featureValuesToCSVAllTidyJoined(
        //     props.data.points.filter((f) =>
        //         featureIsIncludedInCurrentFilter(f, featureFilter)
        //     ),
        //     props.geoLevel
        // );
    }, [
        props.geoLevel,
        props.indicator,
        props.data.points,
        props.featureFilter,
    ]);

    return (
        <>
            <label
                htmlFor={`export-modal-${props.indicator.id}`}
                className="cursor-pointer btn btn-xs btn-circle btn-primary"
            >
                <DownloadIcon fill="white" />
            </label>

            <Portal>
                <input
                    type="checkbox"
                    id={`export-modal-${props.indicator.id}`}
                    className="modal-toggle"
                />
                <label
                    htmlFor={`export-modal-${props.indicator.id}`}
                    className="modal cursor-pointer"
                >
                    <ul
                        tabIndex={0}
                        className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52"
                    >
                        <li>
                            <a
                                download={getFileName(
                                    props.indicator,
                                    props.featureFilter,
                                    i18n.language,
                                    'csv'
                                )}
                                href={`data:text/csv;charset=utf-8,${encodeURIComponent(
                                    csv
                                )}`}
                            >
                                CSV (.csv)
                            </a>
                        </li>
                        <li>
                            <DownloadIndicatorButtonXLSX {...props} />
                        </li>
                    </ul>
                </label>
            </Portal>
        </>
    );
}

export function DownloadIndicatorButtonXLSX(props: Props) {
    const { i18n } = useTranslation();
    i18n.language;

    return (
        <button
            onClick={() => {
                const rows = featureValuesToRows(
                    props.data.points.filter((f) =>
                        featureIsIncludedInCurrentFilter(f, props.featureFilter)
                    ),
                    props.indicator,
                    props.geoLevel
                );
                const worksheet = XLSX.utils.json_to_sheet(rows);

                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, 'Dates');

                XLSX.writeFile(
                    workbook,
                    getFileName(
                        props.indicator,
                        props.featureFilter,
                        i18n.language,
                        'xlsx'
                    ),
                    { compression: true }
                );
            }}
        >
            Excel (.xlsx)
        </button>
    );
}

export function featureValuesToRows(
    features: Feature<Geometry, GeoJsonProperties>[],
    indicator: Indicator,
    geoLevel: GeoLevel
) {
    if (features.length === 0) {
        return [];
    }

    const referenceValues = features[0].properties?.values[indicator.id];
    if (!referenceValues) {
        return [];
    }

    const geoLevelCode = geoLevel + 'kod';

    const years = Object.keys(referenceValues);

    const rows: any[] = [];

    features.forEach((feature) => {
        const row = {
            [geoLevelCode]: feature.properties?.id,
            kommun: feature.properties?.kommunnamn,
            namn: feature.properties?.[geoLevel],
        };

        years.forEach((year) => {
            row[year] = feature.properties?.values[indicator.id][year]
                ? parseFloat(
                      feature.properties?.values[indicator.id][year].toFixed(1)
                  )
                : null;
        });
        rows.push(row);
    });

    return rows;
}
