import { MouseEvent, useRef, useState } from 'react';
import { useOnClickOutside } from '../../hooks/use-clickoutside.hook';
import { Data } from '../../hooks/use-data.hook';
import { Indicator, SetVars, TimeRangeMap } from '../../types';
import { DownloadIndicatorButton } from '../DownloadIndicatorButton';
import { File, FileSelect } from '../FileViewer';
import { XAxisIcon, YAxisIcon } from '../Icons';
import { Portal } from '../Portal';

import './var-select.css';
import { useTranslation } from 'react-i18next';
import { useIndicatorFiles } from '../../hooks/use-indicator-files';
import { getRouteApi } from '@tanstack/react-router';

const routeApi = getRouteApi('/');

type TooltipButtonProps = {
    indicator: Indicator | 'kommun';
};
function TooltipButton({ indicator }: TooltipButtonProps) {
    const { i18n } = useTranslation();

    const [viewerIsOpen, setViewerIsOpen] = useState<boolean>(false);
    const [viewerPos, setViewerPos] = useState<{ x: number; y: number }>({
        x: 500,
        y: 500,
    });

    const handleClick = (e: MouseEvent) => {
        setViewerPos({
            x: e.clientX,
            y: e.clientY,
        });
        setViewerIsOpen(!viewerIsOpen);
    };

    const ref = useRef<HTMLDivElement | null>(null);

    useOnClickOutside(ref, (e) => {
        setViewerIsOpen(false);
    });

    if (indicator === 'kommun') {
        return (
            <div>
                {viewerIsOpen && (
                    <Portal>
                        <div
                            ref={ref}
                            className="absolute bg-white shadow-xl rounded-lg border-secondary border-2 p-3 max-w-md"
                            style={{
                                top: `${viewerPos.y}px`,
                                left: `${viewerPos.x}px`,
                            }}
                        >
                            <h2 className="font-bold text-lg">Kommun</h2>
                        </div>
                    </Portal>
                )}
                <button
                    onClick={handleClick}
                    type="button"
                    className={`btn btn-xs btn-circle btn-primary text-white ${
                        viewerIsOpen ? 'btn-secondary' : ''
                    }`}
                >
                    ?
                </button>
            </div>
        );
    }

    return (
        <div>
            {viewerIsOpen && (
                <Portal>
                    <div
                        ref={ref}
                        className="absolute bg-white shadow-xl rounded-lg border-secondary border-2 p-3 max-w-md"
                        style={{
                            top: `${viewerPos.y}px`,
                            left: `${viewerPos.x}px`,
                            width: '400px',
                        }}
                    >
                        <h2 className="font-bold mb-3 text-lg">
                            {indicator.label?.[i18n.language] ??
                                indicator.label?.['sv']}
                        </h2>
                        {indicator.description && (
                            <p className="mb-3">
                                {' '}
                                {indicator.description?.[i18n.language] ??
                                    indicator.description?.['sv']}
                            </p>
                        )}
                        {(indicator.sourceUrl || indicator.sourceName) && (
                            <p>
                                Källa:{' '}
                                {indicator.sourceUrl ? (
                                    <a
                                        href={indicator.sourceUrl}
                                        target="_blank"
                                        className="link"
                                    >
                                        {indicator.sourceName ??
                                            indicator.sourceUrl}
                                    </a>
                                ) : (
                                    indicator.sourceName
                                )}
                            </p>
                        )}
                    </div>
                </Portal>
            )}
            <button
                onClick={handleClick}
                type="button"
                className={`btn btn-xs btn-circle btn-primary text-white ${
                    viewerIsOpen ? 'btn-secondary' : ''
                }`}
            >
                ?
            </button>
        </div>
    );
}

type Props = {
    data: Data;
    indicatorFiles: File[];
    indicatorsMap: Record<string, Indicator>;
} & SetVars;

export function VarSelect({
    indicatorFiles,
    setXVar,
    setYVar,
    setRVar,
    setCVar,
    xVar,
    yVar,
    rVar,
    cVar,
    data,
    indicatorsMap,
}: Props) {
    const { t } = useTranslation();
    const search = routeApi.useSearch();

    return (
        <div>
            <div className="mb-3 flex flex-row items-center gap-2">
                <h2 className=" text-lg">{t('indikatorer')}</h2>
            </div>
            <div className="vars">
                <FileSelect
                    files={indicatorFiles}
                    value={yVar.id}
                    label="y"
                    labelIcon={<YAxisIcon />}
                    indicatorsMap={indicatorsMap}
                    onChange={(value) => {
                        setYVar(indicatorsMap[value]);
                    }}
                />

                <div className="flex flex-col gap-1">
                    <TooltipButton indicator={yVar} />
                    <DownloadIndicatorButton
                        featureFilter={search.featureFilter}
                        indicator={data.options.indicators.yVar}
                        geoLevel={search.geoLevel}
                        data={data}
                    />
                </div>
                <FileSelect
                    files={indicatorFiles}
                    value={xVar.id}
                    label="x"
                    labelIcon={<XAxisIcon />}
                    indicatorsMap={indicatorsMap}
                    onChange={(value) => {
                        setXVar(indicatorsMap[value]);
                    }}
                />

                <div className="flex flex-col gap-1">
                    <TooltipButton indicator={xVar} />
                    <DownloadIndicatorButton
                        indicator={data.options.indicators.xVar}
                        geoLevel={search.geoLevel}
                        data={data}
                        featureFilter={search.featureFilter}
                    />
                </div>

                <FileSelect
                    files={indicatorFiles}
                    value={rVar.id}
                    label={t('storlek')}
                    indicatorsMap={indicatorsMap}
                    onChange={(value) => {
                        setRVar(indicatorsMap[value]);
                    }}
                />

                <div className="flex flex-col gap-1">
                    <TooltipButton indicator={rVar} />
                    <DownloadIndicatorButton
                        indicator={data.options.indicators.rVar}
                        geoLevel={search.geoLevel}
                        data={data}
                        featureFilter={search.featureFilter}
                    />
                </div>
                <FileSelect
                    files={[
                        { name: 'Kommun', type: 'file', id: 'kommun' },
                        ...indicatorFiles,
                    ]}
                    value={cVar ? cVar.id : 'kommun'}
                    label={t('farg')}
                    indicatorsMap={indicatorsMap}
                    onChange={(value) => {
                        if (value === 'kommun') {
                            return setCVar(null);
                        }
                        setCVar(indicatorsMap[value]);
                    }}
                />

                <div className="flex flex-col gap-1">
                    {cVar && <TooltipButton indicator={cVar} />}
                    {cVar && (
                        <DownloadIndicatorButton
                            featureFilter={search.featureFilter}
                            indicator={cVar}
                            geoLevel={search.geoLevel}
                            data={data}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}
