type Props = {
    fill?: string;
};

export function ChrevronLeft({ fill = 'black' }: Props) {
    return (
        // <svg
        //     xmlns="http://www.w3.org/2000/svg"
        //     height="24px"
        //     viewBox="0 0 24 24"
        //     width="24px"
        //     fill={fill}
        // >
        //     <path d="M0 0h24v24H0V0z" fill="none" />
        //     <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
        //     <path d="M12 10h-2v2H9v-2H7V9h2V7h1v2h2v1z" />
        // </svg>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 -960 960 960"
            width="24px"
            fill={fill}
        >
            <path d="M560-240 320-480l240-240 56 56-184 184 184 184-56 56Z" />
        </svg>
    );
}
