import { useEffect } from 'react';

export function useHandleResize(callback: () => void) {
    useEffect(() => {
        const handleResize = () => {
            callback();
        };

        handleResize();

        window.addEventListener('resize', handleResize);
        window.addEventListener('fullscreenchange', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('fullscreenchange', handleResize);
        };
    }, []);
}
