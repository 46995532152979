import { MouseEvent, ReactNode, useState } from 'react';

import './fileviewer.css';
import { useTranslation } from 'react-i18next';

export type File =
    | ({
          name: string;
          id: string;
          meta?: string;
          subLabel?: JSX.Element;
          disabled?: boolean;
          disabledCause?: string;
      } & (
          | {
                type: 'directory';
                contents: File[];
            }
          | {
                type: 'file';
            }
      ))
    | {
          type: 'divider';
          title: string;
          meta?: string;
      };

type DirectoryProps = {
    slug: string;
    name: string;
    subLabel?: JSX.Element;
    children: ReactNode;
};

function Directory({ name, children, subLabel }: DirectoryProps) {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = (e: MouseEvent) => {
        e.preventDefault();
        setIsOpen(!isOpen);
    };

    return (
        <div className="pl-6">
            <div className="cursor-pointer item" onClick={handleToggle}>
                <label className="swap swap-rotate">
                    <input type="checkbox" checked={!isOpen} readOnly />

                    <svg
                        className="swap-off fill-current w-3 h-3"
                        xmlns="http://www.w3.org/2000/svg"
                        height="24px"
                        viewBox="0 0 24 24"
                        width="24px"
                        fill="#000000"
                    >
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
                    </svg>

                    <svg
                        className="swap-on fill-current w-3 h-3"
                        xmlns="http://www.w3.org/2000/svg"
                        height="24px"
                        viewBox="0 0 24 24"
                        width="24px"
                        fill="#000000"
                    >
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
                    </svg>
                </label>
                {name}
            </div>
            {subLabel && subLabel}
            <div
                style={{
                    overflow: isOpen ? undefined : 'hidden',
                    height: isOpen ? 'auto' : 0,
                }}
            >
                {children}
            </div>
        </div>
    );
}

type FileProps = {
    key: string;
    slug: string;
    file: File & { type: 'directory' | 'file' };
    onClick: (id: string) => void;
    isActive: boolean;
};

function File({ file, onClick, isActive }: FileProps) {
    return (
        <div className={`pl-6 item cursor-pointer`}>
            <button
                type="button"
                disabled={file.disabled}
                onClick={() => {
                    onClick(file.id);
                }}
                className={`text-left text-sm w-full ${
                    isActive ? 'font-bold' : ''
                } ${file.disabled ? 'text-slate-200' : ''}`}
            >
                {file.name}
            </button>
        </div>
    );
}

function TreeRecursive({
    files,
    onClick,
    activeFile,
}: {
    files: File[];
    onClick: (id: string) => void;
    activeFile: string;
}) {
    const { i18n } = useTranslation();

    return (
        <>
            {files.map((item) => {
                if (item.type === 'file') {
                    return (
                        <File
                            isActive={item.id === activeFile}
                            file={item}
                            key={item.name + (item.meta ?? '')}
                            onClick={(id) => onClick(id)}
                            slug={item.name}
                        />
                    );
                }

                if (item.type === 'directory') {
                    return (
                        <Directory
                            subLabel={item.subLabel}
                            name={item.name}
                            slug={item.name}
                            key={item.name + (item.meta ?? '')}
                        >
                            <TreeRecursive
                                onClick={(id) => onClick(id)}
                                files={item.contents}
                                activeFile={activeFile}
                            />
                        </Directory>
                    );
                }

                if (item.type === 'divider') {
                    return (
                        <div key={item.title + (item.meta ?? '')}>
                            <hr />
                            <div className="pl-5 mt-1">
                                <h2>{item.title}</h2>
                            </div>
                        </div>
                    );
                }
            })}
        </>
    );
}

type Props = {
    files: File[];
    onClickFile: (id: string) => void;
    activeFile: string;
};

export function FileViewer({ files, onClickFile, activeFile }: Props) {
    return (
        <TreeRecursive
            activeFile={activeFile}
            onClick={(id) => onClickFile(id)}
            files={files}
        />
    );
}
