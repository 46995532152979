import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

type Props = {
    children: ReactNode;
    selector?: string;
};

export function Portal({ children, selector }: Props) {
    const element = document.querySelector<HTMLDivElement>(
        selector ?? '#portal'
    );
    if (!element) return null;
    return createPortal(children, element);
}
